import { gql } from '@apollo/client';

export default gql`
  query GetExpertAccount($id: ID!) {
    expert(id: $id) {
      id
      account {
        id
        firstName
        lastName
        city
        iban
        bic
        tin
        vat
        settlementCurrency
        minPayableAmount
        address
        province
        postalCode
        country
        accountNo
        sortCode
        updatedAt
        routingNumber
      }
    }
  }
`;
