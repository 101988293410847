const list = (countries, { language } = {}) => {
  if (!language) return countries;

  const names = new Intl.DisplayNames(language, { type: 'region' });
  return countries.map((code) => names.of(code.toUpperCase()));
};

const includes = (countries, country, { language = 'en' } = {}) => {
  if (!country?.toUpperCase) {
    return false;
  }

  if (country.length <= 2) {
    return countries.includes(country.toUpperCase());
  }

  return list(countries, { language }).some((c) => (
    c?.toLowerCase() === country.toLowerCase()
  ));
};

const membership = (countries) => ({
  list: (opts = {}) => list(countries, opts),
  includes: (country, opts = {}) => includes(countries, country, opts),
});

export default membership;
