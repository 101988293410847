import React from 'react';
import { signOut } from '@guuru/auth-web';
import { Dropdown } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import useDrawers from '../../hooks/useDrawers';
import HistoryView from '../../views/drawers/HistoryView';
import SettingsView from '../../views/drawers/SettingsView';
import PayoutsView from '../../views/drawers/PayoutsView';
import IconButton from '../IconButton';

const MoreMenu = function () {
  const { open } = useDrawers();

  const handleSignOut = async () => {
    await signOut();
    window.location.reload();
  };

  const items = {
    history: {
      label: 'Conversation History',
      action: () => open(HistoryView),
    },
    payouts: {
      label: 'Payouts',
      action: () => open(PayoutsView),
    },
    settings: {
      label: 'Settings',
      action: () => open(SettingsView),
    },
    divider: { type: 'divider' },
    logout: {
      label: 'Sign out',
      action: () => handleSignOut(),
    },
  };

  return (
    <Dropdown
      menu={{
        onClick: ({ key }) => items[key].action?.(),
        items: Object.entries(items).map(([key, { action, ...item }]) => (
          item.label ? { key, ...item } : item
        )),
      }}
      trigger={['click']}
      placement="bottomRight"
    >
      <span>
        <IconButton
          icon={<MoreOutlined />}
          onClick={(e) => e.preventDefault()}
        />
      </span>
    </Dropdown>
  );
};

export default MoreMenu;
