import membership from './src/membership';

import EU_COUNTRIES from './src/eu';
import EEA_COUNTRIES from './src/eea';
import EFTA_COUNTRIES from './src/efta';
import SCHENGEN_COUNTRIES from './src/schengen';
import DAC7_COUNTRIES from './src/dac7';

export const eu = membership(EU_COUNTRIES);
export const dac7 = membership(DAC7_COUNTRIES);
export const eea = membership(EEA_COUNTRIES);
export const schengen = membership(SCHENGEN_COUNTRIES);
export const efta = membership(EFTA_COUNTRIES);
