import { gql } from '@apollo/client';

export default gql`
  mutation UpdateAccount($id: ID!, $input: AccountInput!) {
    updateAccount(id: $id, account: $input) {
      id
      firstName
      lastName
      city
      iban
      bic
      settlementCurrency
      minPayableAmount
      address
      postalCode
      country
      province
      accountNo
      sortCode
      updatedAt
      routingNumber
      tin
      vat
    }
  }
`;
