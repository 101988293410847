import EU_COUNTRIES from './eu';

const EEA_COUNTRIES = [
  'IS',
  'LI',
  'NO',
  ...EU_COUNTRIES,
];

export default EEA_COUNTRIES;
